<template>
  <c-pseudo-box mx="0" class="container">

    <c-box
        position="absolute"
        top="18"
        right="20"
    >
      <c-button
          @click="openFilterModal"
          variant-color="vc-orange"
          variant="outline"
          right-icon="filter"
          size="sm"
      >
        Filter
      </c-button>

      <!-- Add Coupon Button -->
      <c-button @click="openAddCouponModal" ml="3" variant="outline" size="sm">
        Add Coupon
      </c-button>
    </c-box>

    <!-- Add Coupon Modal -->
    <c-modal
        :is-open="isAddCouponModalOpen"
        @close="closeAddCouponModal"
        :is-centered="true"
    >
      <c-modal-content>
        <!-- Modal Header -->
        <c-modal-header>Request New Coupon</c-modal-header>
        <c-modal-close-button @click="closeAddCouponModal" />

        <!-- Modal Body -->
        <c-modal-body>
          <c-form-control>
            <c-form-label>Message</c-form-label>
            <c-textarea v-model="couponRequestMessage" />
          </c-form-control>
        </c-modal-body>

        <!-- Modal Footer -->
        <c-modal-footer>
          <c-button variant-color="vc-orange" @click="requestCoupon" mr="3">
            Request
          </c-button>
          <c-button @click="closeAddCouponModal" variant="outline">
            Cancel
          </c-button>
        </c-modal-footer>
      </c-modal-content>
    </c-modal>

    <!-- Filter Dialog -->
    <c-modal
        :is-open="isFilterModalOpen"
        @close="closeFilterModal"
        :closeOnOverlayClick="false"
        size="lg"
        is-centered
        class="rounded-lg"
    >

      <c-modal-content>
        <!-- Modal Header -->
        <c-modal-header>Filter Options</c-modal-header>
        <c-modal-close-button @click="closeFilter" variant-color="pink" />
        <c-modal-body>
          <!-- Filter Checklist -->
          <c-form-control>
            <c-form-label>Select Filters</c-form-label>
            <c-checkbox-group v-model="selectedFilters">
              <c-checkbox class="dark:bg-pink-600" value="coupon">Coupon Code</c-checkbox>
              <c-checkbox variant-color="red" value="status">Status</c-checkbox>
              <c-checkbox variant-color="red" value="date">Date</c-checkbox>
            </c-checkbox-group>
          </c-form-control>

          <c-divider my="6" v-if="selectedFilters.length !== 0" color="gray"></c-divider>

          <!-- Filter by Name -->
          <c-form-control my="4" v-if="selectedFilters.includes('coupon')">
            <c-form-label>Filter by Coupon</c-form-label>
            <c-input placeholder="Enter Company Name" v-model="filterOptions.coupon" />
          </c-form-control>

          <!-- Date Range Filter -->
          <c-form-control my="4" v-if="selectedFilters.includes('date')">
            <c-form-label>Filter by Date Range</c-form-label>
            <c-stack direction="row" spacing="4">
              <c-input type="date" v-model="filterOptions.startDate" />
              <c-input type="date" v-model="filterOptions.endDate" />
            </c-stack>
          </c-form-control>

          <!-- Date Range Filter -->
          <c-form-control my="4" v-if="selectedFilters.includes('status')">
            <c-form-label >Filter by Status</c-form-label>
            <c-stack direction="row" spacing="4">
              <c-box mb="3" w="300px">
                <c-select v-model="status" placeholder="Select Status">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </c-select>
              </c-box>
            </c-stack>
          </c-form-control>

        </c-modal-body>

        <c-divider mt="7" color="gray"></c-divider>

        <!-- Modal Footer -->
        <c-modal-footer>
          <c-button variant-color="vc-orange" @click="applyFilters" mr="3">
            Apply
          </c-button>
          <c-button @click="clearFilters" variant="outline">
            Clear
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <!-- Modal Body -->
    </c-modal>
    <div >
      <div >
        <c-heading as="h1">
          {{ criteria.title }}
        </c-heading>
        <c-text fontWeight="300" color="#495057" v-if="criteria.description">
          {{ criteria.description }}
        </c-text>
      </div>
    </div>


    <div v-if="coupons">
      <c-text mt="8" fontWeight="600">Popular Coupons</c-text>
      <c-simple-grid
          v-if="coupons.length > 3"
          :columns="7"
          spacing-x="20px"
          mb="7"
          mt="1"
      >
        <data-card
            v-for="(datum, index) in popularCoupons"
            :key="datum.code" :index="index"
            :title="datum.coupon_companies.length"
            :subtitle="datum.code"
        >
        </data-card>
      </c-simple-grid>

      <DataTable
          v-if="coupons !== null && coupons.length"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
          stripedRows
          responsiveLayout="stack"
          :paginator="true"
          :rowsPerPageOptions="[10,15,20,50,100]"
          :rows="15"
          :value="filteredCouponData"
          class="table-body p-datatable-sm"
      >
        <template #header>
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <span class="datatable-header-title">Coupons Details</span>
            <c-input
                v-model="searchQuery"
                placeholder="Search..."
                @input="onSearch"
                style="width: 250px; padding: 8px; margin-left: auto;"
            />
          </div>
        </template>
        <Column field="code" header="Code"></Column>
<!--        <Column field="company.name" header="Business"></Column>-->
        <!--      <Column field="company.name" header="Plan"></Column>-->
        <Column field="discount" header="Discount"></Column>
        <Column field="commission" header="Commission"></Column>
        <Column field="status" header="Status"></Column>
        <Column field="created_at" header="Date">
          <template #body="slotProps">
            <div>
              {{fineDate(slotProps.data.created_at)}}
            </div>
          </template>
        </Column>
        <Column header="Actions">
          <template #body="slotProps">
            <c-button style="color: white" size="xs" bg="green.200" @click="copyCode(slotProps.data.code)">
              Copy
            </c-button>
            <c-button style="color: white" size="xs" bg="tomato" ml="2" @click="shareCode(slotProps.data.code)">
              Share
            </c-button>
          </template>
        </Column>
      </DataTable>
    </div>
    <c-flex h="500px" justify="center" align="center" v-else>
      <c-spinner
          thickness="4px"
          speed="0.65s"
          color="tomato"
          size="xl"
      />
    </c-flex>

  </c-pseudo-box>
</template>

<script>
import DataCard from "@/views/Affiliate/components/DataCard.vue";
import DataTable from "primevue/datatable";
import {mapGetters, mapState} from "vuex";
import {getAffiliateCoupons} from "@/services/company";
import moment from "moment";

export default {
  name: "Index",
  components: {DataTable, DataCard},

  methods: {
    copyCode(code) {
      // Copy coupon code to the clipboard
      navigator.clipboard.writeText(code).then(() => {
        this.$toast({
          status: 'success',
          title: 'Copied',
          description: 'Coupon code copied to clipboard',
          position: 'top',
          duration: 3000
        });
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    },

    shareCode(code) {
      // Share coupon code using Web Share API, if supported
      if (navigator.share) {
        navigator.share({
          title: 'Coupon Code',
          text: `Check out this coupon code: ${code}`,
          url: window.location.href
        }).catch(error => {
          console.error('Error sharing:', error);
        });
      } else {
        this.$toast.add({ severity: 'warn', summary: 'Share not supported', detail: 'Your browser does not support the Web Share API' });
      }
    },

    onSearch() {
      const query = this.searchQuery.toLowerCase();
      this.filteredCouponData = this.coupons.filter(item => {
        return (
            item.dateUsed.toLowerCase().includes(query) ||
            (item.company && item.company.name.toLowerCase().includes(query)) ||
            item.commissionPaymentStatus.toLowerCase().includes(query) ||
            item.commissionAmount.toString().includes(query)
        );
      });
    },

    getCoupons(){
      this.loading = true;

      getAffiliateCoupons(this.activeCompany.id)
          .then((res) => {
            console.log('FERGoTingxxx=>', res)
            this.coupons = res.data.coupon;
            this.filteredCouponData = this.coupons; // Initialize filtered data with all coupons
            this.loading = false;
          })
          .catch((e) => {
            console.error(e)
            this.loading = false;
          })
    },
    openFilterModal() {
      this.isFilterModalOpen = true;
    },

    closeFilterModal() {
      this.isFilterModalOpen = false;
    },

    // Apply filter logic
    applyFilters() {
      this.filteredCouponData = this.coupons;

      if (this.selectedFilters.includes('coupon') && this.filterOptions.coupon) {
        this.filteredCouponData = this.filteredCouponData.filter(
            coupon => coupon.code.toLowerCase().includes(this.filterOptions.coupon.toLowerCase())
        );
      }

      if (this.selectedFilters.includes('status') && this.status) {
        this.filteredCouponData = this.filteredCouponData.filter(
            coupon => coupon.status === this.status
        );
      }

      if (this.selectedFilters.includes('date') && this.filterOptions.startDate && this.filterOptions.endDate) {
        const startDate = new Date(this.filterOptions.startDate);
        const endDate = new Date(this.filterOptions.endDate);

        this.filteredCouponData = this.filteredCouponData.filter(
            coupon => {
              const couponDate = new Date(coupon.created_at);
              return couponDate >= startDate && couponDate <= endDate;
            }
        );
      }

      this.closeFilterModal(); // Close modal after applying filters
    },

    closeFilter() {
      this.closeFilterModal();
    },

    clearFilters() {
      this.selectedFilters = [];
      this.filterOptions = {
        coupon: '',
        startDate: '',
        endDate: '',
        status: '',
        // Clear more filter options based on your tableColumns
      };
    },

    openAddCouponModal() {
      this.isAddCouponModalOpen = true;
    },

    requestCoupon() {
      this.closeAddCouponModal();
    },

    fineDate(dat){
      return moment(dat).format('DD-MM-yyyy')
    },

    closeAddCouponModal() {
      this.isAddCouponModalOpen = false;
      this.couponRequestMessage = ''; // Clear the message when the modal is closed
    },
  },

  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      companies: (state) => state.companies,
    }),

    ...mapGetters({
      company: "company/getActiveCompany"
    }),

    popularCoupons() {
      if (!this.coupons || !Array.isArray(this.coupons)) {
        return []; // Return an empty array if `coupons` is not available or is not an array
      }

      // Safely get the coupons sorted by the length of coupon_companies
      return [...this.coupons]
          .filter(coupon => Array.isArray(coupon.coupon_companies)) // Ensure coupon_companies is an array
          .sort((a, b) => b.coupon_companies.length - a.coupon_companies.length) // Sort by the size of coupon_companies
          .slice(0, 3)
    }
  },

  mounted() {
    this.getCoupons()
  },

  data(){
    return {
      searchQuery: '',
      filteredCouponData: '',
      coupons: null,
      loading: false,
      isAddCouponModalOpen: false,
      isFilterModalOpen: false,
      selectedFilters: [], // To store selected filters
      status: '',
      filterOptions: {
        status: '',
        startDate: '',
        endDate: '',
        coupon: '',
      },
      couponRequestMessage: '',
      criteria: {
        title: 'Coupons',
        description:
            `The following are the coupons that are associated to your affiliate account. Contact the admin for new coupons`,
      },

      dashData: [
        {
          id: 1,
          coupon: 'BBV6CJ005W',
          link: 'www.link4vibrant-creator.com',
          discount: '5.8',
          commission: '25.0',
          status: 'inactive'
        },
        {
          id: 2,
          coupon: 'BCV6CJ115W',
          link: 'www.link4vibrant-creator.com',
          discount: '4.9',
          commission: '27.5',
          status: 'active'
        },
        {
          id: 3,
          coupon: 'DFV8CJ775W',
          link: 'www.link4vibrant-creator.com',
          discount: '8.9',
          commission: '29.5',
          status: 'active'
        },
        {
          id: 4,
          coupon: 'BCV6CJ115W',
          link: 'www.link4vibrant-creator.com',
          discount: '4.9',
          commission: '27.5',
          status: 'active'
        },
        {
          id: 5,
          coupon: 'BCV6CJ115W',
          link: 'www.link4vibrant-creator.com',
          discount: '4.9',
          commission: '27.5',
          status: 'active'
        },
        {
          id: 6,
          coupon: 'BBV6CJ005W',
          link: 'www.link4vibrant-creator.com',
          discount: '5.8',
          commission: '25.0',
          status: 'inactive'
        },
        {
          id: 7,
          coupon: 'BBV6CJ005W',
          link: 'www.link4vibrant-creator.com',
          discount: '5.8',
          commission: '25.0',
          status: 'inactive'
        },
        {
          id: 8,
          coupon: 'BBV6CJ005W',
          link: 'www.link4vibrant-creator.com',
          discount: '5.8',
          commission: '25.0',
          status: 'inactive'
        },
        {
          id: 9,
          coupon: 'BCV6CJ115W',
          link: 'www.link4vibrant-creator.com',
          discount: '4.9',
          commission: '27.5',
          status: 'active'
        },
        {
          id: 10,
          coupon: 'BBV6CJ005W',
          link: 'www.link4vibrant-creator.com',
          discount: '5.8',
          commission: '25.0',
          status: 'inactive'
        },

      ],

      dummy: [
        {
          title: '100',
          subtitle: 'BB22R8QTG',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(66,103,178,0.44)',
          textColor: '#4267B2',
        }
        ,{
          title: '88',
          subtitle: 'CBV&G17U',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(193,53,132,0.26)',
          textColor: '#C13584',
        },
        {
          title: '49',
          subtitle: 'CBV&G17U',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(0,43,73,0.24)',
          textColor: '#002B49',
        },
        {
          title: '62',
          subtitle: '7W02N1175',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(255,0,0,0.19)',
          textColor: '#FF0000',
        },
        {
          title: '75',
          subtitle: '7W02N1175',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(0,119,181,0.31)',
          textColor: '#0077B5',
        },

      ],

      tableColumns: [
        {
          action: () => null,
          component: null,
          title: 'Coupon',
          value: 'code',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Discount(%)',
          value: 'discount',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Commission(%)',
          value: 'commission',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Status',
          value: 'status',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Created',
          value: 'created_at',
          disabled: false,
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
}

.table-body{
  font-family: Montserrat,serif
}

.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>