<template>
  <c-box
      height="79px"
      p="2"
      borderRadius="md"
      :bg="cardBackgroundColor"
      width="170px"
  >
    <c-flex
        justifyContent="space-between"
    >
      <c-heading
          fontSize="30px"
          :color="textColor"
      >
        {{ title }}
      </c-heading>
<!--      <svg width="10px" height="16px" fill="#ef5323">-->
<!--        <use :href="datum.icon"></use>-->
<!--      </svg>-->
    </c-flex>
    <c-text mt="2" fontSize="sm" :color="textColor">
      {{ subtitle }}
    </c-text>
  </c-box>
</template>

<script>

export default {
  name: "DataCard",

  computed: {
    cardBackgroundColor() {
      // Set first card to a light orange; others to gray
      return this.index === 0 ? 'rgba(234,87,8,0.37)' : 'rgba(234,87,8,0.37)';
    },
    textColor() {
      // Set deeper orange for the first card text, dark gray for others
      return this.index === 0 ? '#ea5708' : '#ea5708';
    }
  },

  data(){
    return{
      icon: null,
    };
  },
  props: ['title', 'subtitle', 'index'],

  methods: {
    darkenColor(hex, factor) {
      // Convert hex to RGB, reduce brightness, and convert back to hex
      let r = parseInt(hex.slice(1, 3), 16);
      let g = parseInt(hex.slice(3, 5), 16);
      let b = parseInt(hex.slice(5, 7), 16);

      r = Math.floor(r * factor);
      g = Math.floor(g * factor);
      b = Math.floor(b * factor);

      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }
  }
};
</script>

<style scoped>

</style>